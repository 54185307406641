const PageViews = require('@piwikpro/gatsby-plugin-piwik-pro').PageViews;

exports.onRouteUpdate = () => {
  if (`requestAnimationFrame` in window) {
    requestAnimationFrame(() => {
      requestAnimationFrame(() =>
        setTimeout(() => PageViews.trackPageView(), 0)
      );
    });
  } else {
    setTimeout(() => PageViews.trackPageView(), 32);
  }
};

let nextRoute = ``;

exports.onPreRouteUpdate = ({location}) => {
  nextRoute = location.pathname;
};

window.addEventListener('unhandledrejection', (event) => {
  if (/loading chunk \d* failed./i.test(event.reason)) {
    if (nextRoute) {
      window.location.pathname = nextRoute;
    }
  }
});
